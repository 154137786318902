.title-main{
	text-align: left;
	margin-bottom: 15px;
	margin-top: 15px;
	border-bottom: 1px solid rgba(255,255,255,.3);
	@include headings{
		font-weight: bold;
		// text-transform: capitalize;
		font-size: rem-calc(16);
		display: inline-block;
		color: #fff;
		// position: relative;
		margin: 0px;
		padding-bottom: 10px;
		a{
			color: #fff;
		}
		// &:before{
		// 	position: absolute;
		// 	content: "";
		// 	left: 0px;
		// 	bottom: -1px;
		// 	width: 50px;
		// 	height: 2px;
		// 	background-color: $color-2st;
		// }
	}
}
.list-service{
	ul{
		margin: 0px;
		list-style: none;
		display: inline-table;
		padding: 0px;
		li{
			float: left;
			a{
				color: $color-4st;
				font-weight: $font-regular;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}

.back-to-top{
	position: fixed;
	content: "";
	right: 15px;
	bottom: 50px;
	width: 40px;
	height: 40px;
	background-color: transparent;
	text-align: center;
	line-height: 36px;
	color: $color-4st;
	z-index: 999999;
	font-size: rem-calc(20);
	border-radius: 100%;
	border: 2px solid $color-4st;
	&:hover{
		color: $color-4st;
	}
}
.title-other{
	display: inline-table;
	width: 100%;
	margin-bottom: 15px;
	border-bottom: 1px solid rgba(0,0,0,.3);
	font-size: rem-calc(18);
	font-weight: $font-bold;
	padding-bottom: 10px;
	margin-top: 30px;
}
// @media(max-width: 991px){
// 	.list-service{
// 		width: 100%;
// 		ul{
// 			padding-top: 10px;
// 		}
// 	}
// }